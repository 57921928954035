<template>
  <div class="container">
    <div class="game-action">
        <div class="left">
            <!-- 最新上架 -->
            <div class="top">
                <img src="../../../../public/images/home/icon_zxsj.png" alt="最新上架">
                <div class="splice"></div>
                <div class="scroll-container" >
                    <span class="scroll-text" :data-text="newArrivalList" :style="{width:scrollTextStyle}">{{newArrivalList}}</span>
                </div>
            </div>
            <!-- 选择游戏 -->
            <div class="game-select">
                <!-- 导航 -->
                <div class="game-nav">
                    <ul>
                        <li v-for="(item,index) in menuData" :key="index" @click="clickNavHandler(index)" :style="{left:menuSelectedStyle}">
                            <span :style="currentMenuIndex===index?menuSelectedStyle:''">{{item}}</span>
                        </li>
                        <div class="slider" :style="{left:menuSliderLeftStyle}"></div>
                    </ul>
                </div>

                <!-- 图标 -->
                <div class="game-icon">
                    <ul>
                        <template v-if="this.gameListSelected">
                            <li v-for="(item,index) in gameListSelected" :key="index" @click="clickGameItemHandler(item)">
                                <img :src="item.gameIcon" :alt="item.gameName">
                                <span>{{item.gameName}}</span>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 右边 -->
        <div class="right">
            <p class="title">
                <span class="section_01">官方</span>
                <span class="section_02">客服咨询</span>
            </p>
            <!-- 客服验证真伪 -->
            <div class="check">
                <div class="item">
                    <img src="../../../../public/images/home/icon_qq.png" alt="">
                    <input type="text" placeholder="输入客服QQ号码验证真伪">
                    <div class="check-btn">
                        <span>验证</span>
                    </div>
                </div>
                <div class="item">
                    <img src="../../../../public/images/home/icon_wx.png" alt="">
                    <input type="text" placeholder="输入客服微信验证真伪">
                    <div class="check-btn">
                        <span>验证</span>
                    </div>
                </div>
            </div>
            <!-- 最新资讯 -->
            <div class="news">
                <p class="title">
                    <span class="section_01">最新</span>
                    <span class="section_02">资讯</span>
                </p>
                <ul>
                    <li><span>让你的游戏账号交易更加安全可靠</span></li>
                    <li><span>游戏账号交易判断平台正不正规?</span></li>
                    <li><span class="text">lol公认的最强秒人英雄 lol玩家认为最强瞬秒敌人的类似六师弟啊</span></li>
                    <li><span>让你的游戏账号交易更加安全可靠</span></li>
                </ul>
            </div>
            <div class="download-btn" @click="clickDownloadAppBtn()">
                <span>下载App</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getGameIconList,getNewArrivalList } from '../../../services/home';
export default {
   data(){
        return{
            activeName:'second',
            menuData:["热门游戏账号","手游账号","端游账号","游戏推荐"],
            gameList:[],
            gameListSelected:[],
            slider:0,
            currentMenuIndex:0,
            newArrivalList:''
        }
   },
   methods:{
        handleClick(tab, event) {
            console.log(tab, event);
        },
        //加载游戏图标列表
        async loadGameList(){
            try{
                const {data} =  await getGameIconList()
                if(data.code === 200){
                    this.gameList = data.data;
                    this.$store.commit('home/setGameIconList',data.data)
                    this.gameListSelected=this.selectGameList(1);
                }
            }catch(err){}
        },
        //筛选游戏列表
        selectGameList(index){
            //计数器，首页图标最多能放下12个游戏
            let length = 0;
            return  this.gameList.filter(item=>{      
                        if(index==1&&item.asHotGame&&length<12){
                            length++;
                            return item;
                        }else if(index===2&&item.asMobileGame&&length<12){
                            length++;
                            return item;
                        }else if(index===3&&item.asPcGame&&length<12){
                            length++;
                            return item;
                        }else if(index===4&&item.asRecommendGame&&length<12){
                            length++;
                            return item;
                        }
                    })
        },
        //获取最新上架信息 ||'V7 104皮肤 112英雄 星元皮肤1个大v7 104皮肤 10传说 燃星之曲 零号赤焰 时雨天司 诗剑行 可改实名'
        async loadNewArrivalList(){
            try{
                const {data} = await getNewArrivalList();
                if(data.code === 200){
                    this.newArrivalList = data.data
                }
            }catch(err){}
        },
        //点击导航操作
        clickNavHandler(index){
            this.currentMenuIndex = index;
            this.gameListSelected = this.selectGameList(index+1);
        },
        //点击游戏项
        clickGameItemHandler(gameItem){
            this.$router.push({name:'woyaomai',params:{gameItem}});
            this.$store.commit('home/setCurrentGlobalMenuIndex',1);
        },
        clickDownloadAppBtn(){
            this.$router.push({path:"/downloadApp"});
            this.$store.commit('home/setCurrentGlobalMenuIndex',8);
        }
   },
   computed:{
        menuSelectedStyle(){
            return 'font-weight:bold;color:#F02233;';
        },
        menuSliderLeftStyle(){
            let currentMenuHalf = 0;
            let resultLeft = 0;
            this.menuData.forEach((item,index)=>{
                if(index<this.currentMenuIndex){
                    resultLeft += this.menuData[index].length*16;
                }
            })
            currentMenuHalf = (this.menuData[this.currentMenuIndex].length*16)/2-15;
            let resultLeftLength = resultLeft+currentMenuHalf;
            return this.currentMenuIndex*50+resultLeftLength+20+'px';
        },
        scrollTextStyle(){
            return this.newArrivalList&&this.newArrivalList.length*14+'px';
        }
   },
   created(){
     this.loadGameList();
     this.$nextTick(()=>{
        this.loadNewArrivalList();
     })
   }
}
</script>

<style lang="less" scoped>
.container{
    width: calc(100vw);
    .game-action{
        margin: 0 auto;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        .left{
            width: 860px;
            height: 422px;
            .top{
                width: 860px;
                height: 46px;
                background-color: #FFFFFF;
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 10px;
                img{
                    width: 84px;
                    height: 26px;
                    margin-right: 20px;
                }
                .splice{
                    width: 1px;
                    height: 20px;
                    background: #E5E5E5;
                }
                .scroll-container{
                    margin-left: 20px;
                    width: 705px;
                    overflow: hidden;
                    white-space:nowrap;
                    display: flex;
                    align-items: center;
                    .scroll-text{
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: #131415;
                        line-height: 14px;
                        display: inline-block;
                        white-space: nowrap;
                        animation: scrollOne 100s linear infinite;  // 设置动画时长为5秒
                        will-change: transform;
                        // 动态计算实际内容的宽度以实现无缝滚动
                        &:before {
                            content: attr(data-text);
                            right: 100%;
                            white-space: nowrap;
                        }
                    }
                    @keyframes scrollOne {
                        0% {
                            transform: translateX(16.67%); // 开始时在容器中间 705/4228=0.1667
                        }
                        100% {
                            transform: translateX(-150%); // 滚动到-150%以确保无缝衔接
                        }
                    }
                }
            }

            .game-select{
                width: 860px;
                height: 356px;
                margin-top: 20px;
                background-color: #FFFFFF;
                border-radius: 4px;
                .game-nav{
                    width: 860px;
                    height: 56px;
                    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
                    ul{
                        list-style-type: none;
                        padding: 0;
                        display: flex;
                        width: 860px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        li{
                            list-style: none;
                            margin-right: 50px;
                            height: 36px;
                            cursor: default;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span{
                                height: 16px;
                                font-size: 16px;
                                font-family: MicrosoftYaHei;
                                color: #303030;
                                line-height: 16px;
                                &:hover{
                                    height: 16px;
                                    font-size: 16px;
                                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                    font-weight: bold;
                                    color: #F02233;
                                    line-height: 16px;
                                }
                            }
                            &:first-child{
                                margin-left: 20px;
                            }
                        }

                        .slider{
                            position: absolute;
                            left: 52px;
                            bottom: 0;
                            content:' ';
                            display: block;
                            height: 2px;
                            width: 30px;
                            background: #F02233;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }

                .game-icon{
                    width: 860px;
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        width: 860px;
                        li{
                            margin-top: 20px;
                            margin-left: 20px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 120px;
                            height: 120px;
                            border: 1px solid #F0F0F0;
                            img{
                                width: 56px;
                                height: 56px;
                                transition: all 0.3s;
                            }
                            span{
                                margin-top: 13px;
                                font-size: 14px;
                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                color: #000000;
                                cursor: default;
                            }

                            &:nth-of-type(n+7){
                                margin-top: 20px;
                            }

                            &:hover{
                                border: 1px solid #F02233;
                            }
                            &:hover>span{
                                color:#F02233;
                            }
                            &:hover>img{
                                transform: scale(110%);
                            }
                        }

                    }
                }

            }
        }

        .right{
            width: 320px;
            height: 422px;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 20px 20px 0 20px;
            cursor: default;
            .title{
                width: 96px;
                .section_01{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #000000;
                }
                .section_02{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color:#F02233
                }
            }

            .check{
                .item{
                    margin-top: 20px;
                    width: 280px;
                    height: 40px;
                    background: #F5F5F5;
                    border-radius: 4px;
                    display: flex;

                    img{
                        width: 14px;
                        height: 14px;
                        margin: 13px;
                    }
                    input{
                        width: 180px;
                        height: 40px;
                        background: #F5F5F5;
                       border: none;
                       outline: none;
                       &::placeholder{
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: #C3C3C3;
                       }
                    }
                    .check-btn{
                        width: 60px;
                        height: 40px;
                        background: #F5F5F5;
                        border-radius: 4px;
                        line-height: 40px;
                        text-align: center;
                        cursor: pointer;
                        &>span{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #878787;
                        }
                    }
                }
            }

            .news{
                margin-top: 25px;
                width: 280px;
                .title{
                    width: 96px;
                    .section_01{
                        font-size: 16px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        color: #000000;
                    }
                    .section_02{
                        font-size: 16px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        color:#F02233
                    }
                }

                &>ul{
                    list-style-type: none;
                    padding: 0;
                    &>li{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        span{
                            font-size: 12px;
                            font-family: MicrosoftYaHei;
                            color: #666666;
                        }
                        &:first-child{
                            margin-top: 12px;
                        }
                        &:not(:first-child){
                            margin-top: 6px;
                        }
                    }
                }
            }

            .download-btn{
                margin-top: 20px;
                width: 280px;
                height: 56px;
                background: #F02233;
                box-shadow: 0px 4px 4px 0px rgba(240,34,51,0.18);
                border-radius: 4px;
                text-align: center;
                line-height: 56px;
                cursor: pointer;
                span{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>