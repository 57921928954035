<template>
  <div class="content">
      <Header/>
      <GameAction/>
      <GameInfo/>
      <GameFooter/>
  </div>
</template>

<script>
import Header from './component/content-header.vue';
import GameAction from './component/content-game-action.vue';
import GameInfo from './component/content-game-info.vue';
import GameFooter from './component/content-footer.vue';

export default {
   components:{
    Header,
    GameAction,
    GameInfo,
    GameFooter
   }
}
</script>

<style>

</style>